import React from 'react';


  
export default function Responder (){
    return (
    <><h1>Responder</h1>
        <div>
            <ul>
                <li>
                When visiting the web service using the IP address, what is the domain that we are being redirected to?<br></br>
                unika.htb
                </li>
                <li>Which scripting language is being used on the server to generate webpages?<br></br>
                php
                </li>
               
            </ul>
            

            
            
        </div></>
    
    );
}
  