import React, { useState } from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "./header.css";

import { useNavigate } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';

import Container from 'react-bootstrap/Container';
import {BsArrowBarRight} from 'react-icons/bs';
import Offcanvas from 'react-bootstrap/Offcanvas';

function SideBar(props) {
  const handleChange = props.handleChange
  const [show, setShow] = useState(false);
  const background = props.backgroundClass
  const class_name ="nav-offcanvas-header "+background

  const handleClose = () => {
    setShow(false) 
    handleChange(false)}
  const handleShow = () => {
    setShow(true)
    handleChange(true)}

  const navigate = useNavigate();

  const routeChange = (event, param) =>{ 
    if (show){
      setShow(!show)
    }

    navigate(param);
  }

  return (
    <><div className='sidebar'>
        <Navbar key='sm' collapseOnSelect expand="sm" variant="dark">
        <Container fluid>
          {/* <Navbar.Toggle onClick={handleShow} aria-controls="offcanvasNavbar-expand-sm"><BsArrowBarRight/></Navbar.Toggle> */}
          <Navbar.Toggle onClick={handleShow} aria-controls="offcanvasNavbar-expand-sm"><BsArrowBarRight/> Quick Bar</Navbar.Toggle>
          <Navbar.Offcanvas 
          // className={"nav-offcanvas-header "+ {background}}
          className={class_name}
              placement='start'
              onHide={handleClose}
              show={show} 
              id={"offcanvasNavbar-expand-sm"}
              aria-labelledby={"offcanvasNavbarLabel-expand-sm"}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={"offcanvasNavbarLabel-expand-sm"}>
                  Quick Bar
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav className="flex-column justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={event => routeChange(event, '/resources')}>Resources</Nav.Link>
                  <Nav.Link onClick={event => routeChange(event, '/osimodel')}>OSI Model</Nav.Link>
                  <NavDropdown title="Protocols" id="nav-dropdown">
                      <NavDropdown.Item  onClick={event => routeChange(event, '/Resources/ftp')} >FTP</NavDropdown.Item>
                      <NavDropdown.Item  onClick={event => routeChange(event, '/Resources/smb')} >SMB</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="PenTesting Tools" id="nav-dropdown">
                      <NavDropdown.Item  onClick={event => routeChange(event, '/Resources/nmap')} >NMAP</NavDropdown.Item>
                      <NavDropdown.Item  onClick={event => routeChange(event, '/Resources/gobuster')} >GoBuster</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="HackTheBox" id="nav-dropdown">
                      <NavDropdown.Item  onClick={event => routeChange(event, '/HackTheBox')} >HackTheBox</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item  onClick={event => routeChange(event, '/HackTheBox/appointment_1')} >Appointment</NavDropdown.Item>
                      <NavDropdown.Item  onClick={event => routeChange(event, '/HackTheBox/sequel_1')} >Sequel</NavDropdown.Item>
                      <NavDropdown.Item  onClick={event => routeChange(event, '/HackTheBox/crocodile_1')} >Crocodile</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        </div>
    </>
  );
}

export default SideBar;