import React from 'react';


  
function Crocodile (){
    return (
    <><h1>Crocodile</h1>
        <div>
            <ul>
                <li>
                What Nmap scanning switch employs the use of default scripts during a scan?<br></br>
                -sC
                </li>
                <li>
                What service version is found to be running on port 21?<br></br>
                Running:
                nmap 10.129.1.15 -Pn -T5 -vvv -sC -p21
                we see that it is  vsFTPd 3.0.3
                </li>
                <li>What FTP code is returned to us for the "Anonymous FTP login allowed" message?<br></br>
                    Command: ftp 10.129.1.15
                    We are than prompt to log in. 
                    Log in useing the default anonymous
                    We see that it was a succesful login and return 230
                </li>
                <li>
                After connecting to the FTP server anonymously,
                 what command can we use to download the files we find on the FTP server?<br></br>
                get   
                </li>
                <li>
                    use ls to see the files.<br></br>
                    Command to download the file:<br></br>
                    get allowed.userlist<br></br>
                </li>
                <li>
                What is one of the higher-privilege sounding usernames in 'allowed.userlist' 
                that we download from the FTP server?<br></br>
                Reading the userlist with cat we can see that it is admin.
                </li>
                <li>
                What version of Apache HTTP Server is running on the target host?<br></br>
                If unsure of what port use RustScan to see the open ports
                Run nmap with the -sV flag on the IP
                nmap 10.129.1.15 -Pn -T5 -vvv -sC -p80 -sV
                Results: Apache httpd 2.4.41
                </li>
                
                <li>
                What switch can we use with Gobuster to specify we are looking for specific filetypes?<br></br>
                -x
                </li>
                <li>
                Which PHP file can we identify with directory brute force that will provide 
                the opportunity to authenticate to the web service?<br></br>
                Running the command:
                gobuster dir -x php -u 10.129.1.15 -w /usr/share/seclists/Discovery/Web-Content/common.txt -e 
                NOTE: this will only work in you have seclists installed
                Now we can see the endpoint is: login.php
                </li>
                <li>
                Now that we have the login page we need to get the username and password.
                We know the username is admin
                Check the ftp server we see that is also a file called allowed.userlist.passwd 
                trying each password with admin we are able to access the login page and capture the flag
                

                </li>
            </ul>
            

            
            
        </div></>
    
    );
}
  
export default Crocodile;