import {React} from 'react';
import {useLocation} from 'react-router-dom';



 function Search() {
    const location = useLocation();
    return ( 
      <>
      {location.state.returns.length == 0 ?
        <h1 key={1}>no searches</h1>
      :
        location.state.returns.map((value, i) => {
          return <><a key={i} className='BodyFont' href={value}>{value}</a><br></br></>
      })}
      </>
    )
  }

export default Search;