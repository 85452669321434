import React from 'react';


  
function Gobuster (){
    return (
    <><h1>My FTP notes</h1>
        <div>
            <ul>
                <li>
                Gobuster is one tool used to brute force directories on a webserver. 
                What switch do we use with Gobuster to specify we're looking to discover directories, and not subdomains?
                dir
                </li>
                <li>
                Gobuster switch to specify we are looking for specific filetypes.
                -x
                </li>
                <li>
                To brute force a website with username and password:
                gobuster vhost -u http://10.129.1.15/login.php -w /usr/share/wordlists/dirb/common.txt

                </li>

                
            </ul>
            
        </div></>
    
    );
}
  
export default Gobuster;