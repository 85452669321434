import React from 'react';
  
function Home (){
    return (
        <><h1>My Notes and Documentation</h1>
        <p>
            On this site you'll find some of the techniques/procedures
            I've learned while coding and growing my experience in the cyber field.
            
        </p>
        </>
    )
}
  
export default Home;