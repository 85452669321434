import React from 'react';


  
function Appointment (){
    return (
    <><h1>Appointment</h1>
        <div>
            <ul>
                <li>
                    SQL: Structured Query Language
                </li>
                <li>
                What is one of the most common type of SQL vulnerabilities? <br></br>
                sql injection
                </li>
                <li>
                PII: <br></br>Personally identifiable information
                </li>
                <li>
                What is the 2021 OWASP Top 10 classification for this vulnerability?<br></br>
                A03:2021-Injection
                </li>
                <li>
                What does Nmap report as the service and version that are running on port 80 of the target?<br></br>
                sudo nmap 10.129.143.160 -sV -Pn -T5 -p 80    
                We see : <br></br>Apache httpd 2.4.38 ((Debian))

                </li>
                <li>
                What is the standard port used for the HTTPS protocol?<br></br>
                443

                </li>
                <li>
                What is a folder called in web-application terminology?<br></br>
                directory
                </li>
                <li>
                What is the HTTP response code is given for 'Not Found' errors?<br></br>
                404
                </li>
                <li>
                What is the HTTP response code is given for 'Not Found' errors?<br></br>
                404
                </li>
                <li>
                Gobuster is one tool used to brute force directories on a webserver.
                 What switch do we use with Gobuster to specify we're looking to discover directories, and not subdomains?<br></br>
                dir
                </li>
                <li>
                What single character can be used to comment out the rest of a line in MySQL?<br></br>
                #
                </li>
                <li>
                Go to the login page located at the IP
                Username:admin'#
                Password:test
                See page for Congratulations and the flag
                </li>

            </ul>
            
        </div></>
    
    );
}
  
export default Appointment;