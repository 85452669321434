import React from 'react';


  
function Sequel (){
    return (
    <><h1>Sequel</h1>
        <div>
            <ul>
                <li>
                During our scan, which port do we find serving MySQL?<br></br>
                Instead of using NMAP, I use RustScan since it is faster.
                rustscan -r 1-65535 -a 10.129.108.246
                From rustscan we see that port 3306 is open. So now we sill run this through NMAP.

                </li>
                <li>
                We need to add the -sC flag on the nmap
                In this case, adding '-sC' to run safe scripts
                sudo nmap 10.129.108.246 -sV -sC -Pn -T5 -p 3306 -vvv
                We can see that MariaDB is running on this port

                </li>
                <li>
                With mysql we can pass a username with the -u flag.<br></br>
                An -p flag with prompt the user to enter a password
                </li>
                <li>
                The default user for MySQL is root and by default it has no password
                </li>

                <li>
                In SQL, what symbol can we use to specify within the query that we want to display everything inside a table?<br></br>
                *
                </li>
                <li>
                In SQL, what symbol do we need to end each query with?<br></br>
                ;
                </li>
                <li>
                We can connect to the DB useing mysql 10.129.108.246 -u root
                Than, running the command SHOW DATABASES;
                we can see the databases currently available.
                The oddball out is the htb DB.

                </li>
                <li>
                    Now we can print the entries of this DB by running:
                    use htb
                    Than, 
                    SHOW tables;
                    Now, to print the entries 
                    SELECT * FROM databasetablename
                </li>
                <li>
                    Checking both tables we see that 
                    SELECT * FROM config 
                    results in the table that has the flag.
                </li>
            </ul>
            
        </div></>
    
    );
}
  
export default Sequel;