import './App.css';

import React, { Suspense, lazy, useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from './Components/header'
import SideBar from './Components/sidebar';

import Home from './Routes/home';
import Contact from './Routes/contact';
import Resources from './Routes/resources';
import Search from './Components/search'
import PrivacyPolicy from './Routes/PrivacyPolicy';

import HackTheBox from './Routes/hackthebox';
import Appointment from './Routes/HackTheBox/StartingPointTier1Machines/Appointment_1';
import Crocodile from './Routes/HackTheBox/StartingPointTier1Machines/Crocodile_1';
import Responder from './Routes/HackTheBox/StartingPointTier1Machines/Responder_1';

// import ReactAPI from './Routes/resources/reactapi'
import FTP from './Routes/resources/ftp'
import SMB from './Routes/resources/smb'
import Gobuster from './Routes/tools/Gobuster';
import NMAP from './Routes/tools/Nmap';
import Sequel from './Routes/HackTheBox/StartingPointTier1Machines/Sequel_1';



// import background from './Images/pexels-pixabay-256502.jpg'
import CookieConsent, { Cookies } from "react-cookie-consent";

import background from './Images/istockphoto-1415835098-170667a.jpg'

import AdComponent from './Components/assense.js'

import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import OSIModel from './Routes/OSIModel';

function App() {
  const[vw,setVw] = useState( Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0))
  const[quickbarshow, setQuickBarShow] = useState(true)
  const handleChange =  (is_shown) => {
    setQuickBarShow(is_shown);
  };

  useEffect(() => {
    function handleResize() {
      setVw(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const all_routes = [
    '/',
    '/contact',
    '/resources',
    '/resources/ftp',
    '/resources/smb',
    '/hackthebox'
  ]

  return (
      <>
    <Container fluid className="d-flex vh-100 flex-column" >
        <BrowserRouter>
          <Row style={{ paddingLeft:'0px', paddingRight:'0px' ,borderBottom:"solid"}}>
            <Col >
              <Container fluid style={{ paddingLeft:'0px', paddingRight:'0px'
              }}>
                <Header urls={all_routes}  />
              </Container>
            </Col>
          </Row>
              {vw <=575? 
              <><Row className='justify-content-center '
              style={{ borderBottom: "solid"}}
              ><SideBar handleChange={handleChange} backgroundClass="side-background"/>
                  </Row><Row className='content-container'><Col><Routes>

                <Route exact path='/' element={<Home />}></Route>
                <Route exact path='/contact' element={<Contact />}></Route>
                {/* <Route exact path='/privacypolicy' element={<PrivacyPolicy />}></Route> */}
                <Route exact path='/osimodel' element={<OSIModel />}></Route>

                <Route exact path='/resources' element={<Resources />}></Route>
                <Route exact path='/resources/ftp' element={<FTP />}></Route>
                <Route exact path='/resources/smb' element={<SMB />}></Route>
                <Route exact path='/resources/gobuster' element={<Gobuster />}></Route>
                <Route exact path='/resources/nmap' element={<NMAP />}></Route>
                <Route exact path='/hackthebox' element={<HackTheBox />}></Route>
                <Route exact path='/hackthebox/appointment_1' element={<Appointment />}></Route>
                <Route exact path='/hackthebox/sequel_1' element={<Sequel />}></Route>
                <Route exact path='/hackthebox/crocodile_1' element={<Crocodile />}></Route>
                <Route exact path='/hackthebox/responder_1' element={<Responder />}></Route>
                <Route exact path='/search' element={<Search />}></Route>
                </Routes></Col></Row></> 
          :
          <Row className='flex-fill  justify-content-center content-row '
            style={{
              // backgroundImage: `url(${background}`,
              // backgroundPosition: 'center',
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              paddingTop: '0px',
            }}> 

            <><Col xs={'auto'} className='content-container' style={{ paddingTop: '0px', borderRight: "solid", }}>
                <SideBar handleChange={handleChange}/>
              </Col><Col className='content-container' style={{ paddingTop: '10px', }}>
                  <Routes>

                    <Route exact path='/' element={<Home />}></Route>
                    <Route exact path='/contact' element={<Contact />}></Route>
                    {/* <Route exact path='/privacypolicy' element={<PrivacyPolicy />}></Route> */}
                    <Route exact path='/osimodel' element={<OSIModel />}></Route>

                    <Route exact path='/resources' element={<Resources />}></Route>
                    <Route exact path='/resources/ftp' element={<FTP />}></Route>
                    <Route exact path='/resources/smb' element={<SMB />}></Route>
                    <Route exact path='/resources/gobuster' element={<Gobuster />}></Route>
                    <Route exact path='/resources/nmap' element={<NMAP />}></Route>

                    <Route exact path='/hackthebox' element={<HackTheBox />}></Route>
                    <Route exact path='/hackthebox/appointment_1' element={<Appointment />}></Route>
                    <Route exact path='/hackthebox/sequel_1' element={<Sequel />}></Route>
                    <Route exact path='/hackthebox/crocodile_1' element={<Crocodile />}></Route>


                    <Route exact path='/search' element={<Search />}></Route>
                  </Routes>
                </Col></>
            <Col  xs="auto"></Col>
          </Row>}

        </BrowserRouter>


        {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
        </CookieConsent> */}

      </Container>
      </> 

       

  );
}

export default App;
