import React from 'react';
import osi_model from '../Images/osi-model.png'
function OSIModel (){
  return (
    <><h1><b>OSI Model</b></h1>
    <p>
        The OSI model breaks up how computer systems use to communicate over networks.
        It it broken up into 7 layers. Unlike the TCP/IP(//todo: insert HREF link) model which only has 5.
    </p>
    <img src={osi_model} alt="osi_model" />
    <ol>   
      <li>Physical layer</li>
        <p>
            This is the layer that convert bits into electrical, radio, or optical signals.
            It is responsible for sending and receiving the raw data between devices. 
        </p>
      <li>Data link layer</li>
        <p>
          This layer is used as a link between two nodes. It dectect and corrects (if possible)
          errors that could of occured in the physical layer. 
          There are two main components, LLC and MAC. 
          The LLC handles identify network protocols and error checking. 
          The MAC uses MAC addresses to connect devices and define permissions to send and receive data. 
          MACSec can me applied to this layer for authenticated encryption.
          

        </p>
      <li>Network layer</li>
      <p>
        This layer handles transferring data through network packets from a source to a destination host. 
        </p>
      <li>Transport layer</li>
        <p>
        This layer handles the protocols to provide end-to-end communication services for applications. 
        The two main transport protocols used are TCP and UDP. 
        TCP is used for connected devices and UDP is used for connectionless devies. 
        </p>
      <li>Session layer</li>
        <p>
          This layer creates the setup, connenctions and teardown between two or more computers. 
          DNS operates at this layer. 
          Some common functions of this layer is user login, name lookup, and user logout. 
          FTP Clients and NFS clients are also handled in this layer. 

        </p>
      <li>Presentation layer</li>
      <p>
        This layer handles data formatting and data translation. This typcially includes
        protocol conversion, data encryption, decryption, compression, and decompression. 
        It also transforms data into a form that is acceptable for the Application Layer.
      </p>
      <li>Application layer</li>
      <p>
        This is the layer that a user interacts with directly. 
        The functioning of this layer typcially includes file sharing, message handling, and database access. 
        Some common protocols used at this layer are HTTP, FTP, SMB/CIFS, TFTP, and SMTP.
      </p>
    </ol>
    </>
  )
}
  
export default OSIModel;