import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdComponent from '../Components/assense';

  
function Resources (){
  const navigate = useNavigate();

    return (
        <>
        <Row>
          <Col>
          <h1>Protocols</h1>
          <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>FTP</Card.Title>
            <Card.Text>
              My notes regarding FTP: File Transfer Protocol
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('ftp')}>
                Go
            </Button>
          </Card.Body>
          </Card>

          <Card className='bg-dark text-info' style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>SMB</Card.Title>
                <Card.Text>
                  My notes regarding SMB: Server Message Block
                </Card.Text>
                <Button variant="primary"
                  onClick={() => navigate('smb')}
                >Go</Button>
              </Card.Body>
            </Card>
        </Col>

        <Col>
        <h1>Tools</h1>
        <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>NMAP</Card.Title>
            <Card.Text>
              My notes regarding NMAP
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('nmap')}>
                Go
            </Button>
          </Card.Body>
          </Card>

          <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>GoBuster</Card.Title>
            <Card.Text>
              My notes regarding GoBuster
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('gobuster')}>
                Go
            </Button>
          </Card.Body>
          </Card>

          {/* <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>RustScan</Card.Title>
            <Card.Text>
              My notes regarding RustScan
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('ftp')}>
                Go
            </Button>
          </Card.Body>
          </Card> */}
        </Col>
        </Row>
 
        </>
      );
}
  
export default Resources;