import React from 'react';


  
export default function SMB (){
    return (
    <><h1>My SMB notes</h1>
        <div>
            <ul>
                <li>
                    Port that SMB listens on: 445
                </li>

                <li>
                'flag' or 'switch' we can use with the SMB tool to 'list' the contents of the share: -L
                </li>
                {/* <dt>
                Username that is used over FTP when you want to log in without having an account
                </dt>
                <dd>anonymous</dd> */}
            </ul>
            
        </div></>
    
    );
}
  
