import React from 'react';

import { CopyBlock, dracula } from "react-code-blocks";
  
function FTP (){
    return (
    <>
    <h1><b>FTP</b></h1>
      <div>
        <p>
        FTP stands for File Transfer Protocol. It is used to send files between computers.

        </p>
      </div>

    <h1>Linux FTP Command</h1>
        <p>The format of command is shown below.</p>
      <CopyBlock
      text='ftp [options] [IP]'
      language='bash'
      showLineNumbers={false}
      theme={dracula}
      codeBlock
      />
        <div>
            <ul>
                <li>
                    Login with command: ftp 'ipaddress'
                </li>
                <li>
                    Port that FTP listens on: 21
                </li>
                <li>
                    Secure FTP: SFTP
                </li>
                <li>
                Username that is used over FTP when you want to log in without having an account: anonymous
                </li>
                <li>
                    Command to download files on an FTP server:
                    get
                </li>
                <li>
                    use ls to list the files
                </li>
            </ul>
            
        </div></>
    
    );
}
  
export default FTP;