import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Offcanvas from 'react-bootstrap/Offcanvas';

import "./header.css";
import navbar_menu_icon from "../Images/navbar_menu_icon.png"

import Fuse from 'fuse.js'
import { useNavigate } from "react-router-dom";

import Stack from 'react-bootstrap/Stack';


import Container from 'react-bootstrap/Container';



import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from 'react-router-dom';


function Header(urls) {
  const [makeSearch, setMakeSearch] = useState({returns:[]});
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const routeChange = (event, param) =>{ 
    setShow(!show)
    navigate(param);
  }

  const handleToggle = (e) =>{
    setShow(!show)
  }
  const handleChange = (e, param) =>{
    setMakeSearch(e.target.value)
  }
  const click = (e) =>{ 
    if (makeSearch.length>0) {
      setShow(!show)
      const fuse = new Fuse(urls['urls'])
      const matches = fuse.search(makeSearch) 
      const returns = []

      matches.map((match)=>{
        returns.push(match.item)
      })

      navigate('/search', {state:{returns}})
      // navigate('/search', matches)
  }
  }

  return (
    <>
        <Navbar key={false} expand={show} >
        <Container fluid>

          <Navbar.Brand href="/" className='WebsiteName'>WhatWeDicuss</Navbar.Brand>

            <Navbar.Toggle  aria-controls={`offcanvasNavbar-expand-${show}`}
            onClick={handleShow} 
            >
              <img src={navbar_menu_icon} alt="navbar_menu_icon" 
                style={{
                
                  paddingTop: '0px', paddingBottom: '0px',
                  width: '25px', height: '25px' }}/>
            </Navbar.Toggle>

            <Navbar.Offcanvas 
              className="nav-offcanvas-header header-background"
            
              show={show}
              id={`offcanvasNavbar-expand-${show}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${show}`}
              placement="end"
              onHide={handleClose}
              
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${show}`}>
                  WhatWeDiscuss
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body className='BodyFont'>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={event => routeChange(event, '/')}>Home</Nav.Link>
                  <Nav.Link onClick={event => routeChange(event, '/resources')}>Resources</Nav.Link>
                  <Nav.Link onClick={event => routeChange(event, '/osimodel')}>OSI Model</Nav.Link>
                  <Nav.Link onClick={event => routeChange(event, '/hackthebox')}>HackTheBox</Nav.Link>
                </Nav>
                </Offcanvas.Body>
                <Offcanvas.Body className='BodyFont NavStaticBottom'>
                <Stack >
                <Nav>
                  <Nav.Link onClick={event => routeChange(event, '/contact')}>Contact</Nav.Link>
                  {/* <Nav.Link onClick={event => routeChange(event, '/privacypolicy')}>Privacy Policy</Nav.Link> */}
                </Nav>

                
                <Form className="d-flex " >
                  <Form.Control
                    type="search"
                    defaultValue=""
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    onChange={handleChange}
                  />
                  <Button onClick={click} variant="outline-success">Search</Button>
                </Form>
                </Stack>
                </Offcanvas.Body>

              
            </Navbar.Offcanvas>
            </Container>
        </Navbar>

    </>
  );
}

export default Header;