import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

  
function HackTheBox (){
  const navigate = useNavigate();

    return (
        <>
        <Row>       
          <Col>
          <h1>STARTING POINT - T1 Machine </h1>
          <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Appointment</Card.Title>
            <Card.Text>
              My notes regarding T1 Machine Appointment
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('appointment_1')}>
                Go
            </Button>
          </Card.Body>
          </Card>
          <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Sequel</Card.Title>
            <Card.Text>
              My notes regarding T1 Machine Sequel
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('sequel_1')}>
                Go
            </Button>
          </Card.Body>
          </Card>

          <Card className='bg-dark text-info' style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Crocodile</Card.Title>
            <Card.Text>
              My notes regarding T1 Machine Crocodile
            </Card.Text>
            <Button variant="primary"
              onClick={() => navigate('crocodile_1')}>
                Go
            </Button>
          </Card.Body>
          </Card>

    
        </Col>
        </Row>
 
        </>
      );
}
  
export default HackTheBox;