import React from 'react';

import { CopyBlock, dracula } from "react-code-blocks";
  
function NMAP (){
    return (
    <><h1>My NMAP notes</h1>
      <div> 
        <CopyBlock
              text='nmap 10.129.39.133 -Pn -T5 -p- -vvv -sS'
              language='bash'
              showLineNumbers={false}
              theme={dracula}
              codeBlock
              />
              <br></br>
        <ul>
          
         
          <li>
          <code>-Pn: Treat all hosts as online -- skip host discovery</code>
          <li><code>-sS/sT/sA/sW/sM: TCP SYN/Connect()/ACK/Window/Maimon scans</code></li>
            <div>Sends singal flag during the connect. Speeds up nmap scans</div>
          </li>
          <li><code>-p-: Scans all ports</code></li>
          <li><code>With no -p scans the top 1000 ports</code></li>
        </ul>
          
      </div></>
    
    );
}
  
export default NMAP;